import * as Contract from '@tableau/api-external-contract-js';

export class PulseUrlChangedEvent implements Contract.PulseUrlChangedEvent {
  private _oldUrl: string;
  private _newUrl: string;
  private _context: string | undefined;

  public get oldUrl(): string {
    return this._oldUrl;
  }

  public get newUrl(): string {
    return this._newUrl;
  }

  public get context(): string | undefined {
    return this._context;
  }

  public constructor(oldUrl: string, newUrl: string, context?: string) {
    this._oldUrl = oldUrl;
    this._newUrl = newUrl;
    this._context = context;
  }
}
