import * as Contract from '@tableau/api-external-contract-js';

export class PulseInsightDiscoveredEvent implements Contract.PulseInsightDiscoveredEvent {
  private _id: string;
  private _characterization: string;
  private _markup: string;
  private _question: string;
  private _score: number;
  private _type: string;
  private _version: number;

  public get id(): string {
    return this._id;
  }

  public get characterization(): string {
    return this._characterization;
  }

  public get markup(): string {
    return this._markup;
  }

  public get question(): string {
    return this._question;
  }

  public get score(): number {
    return this._score;
  }

  public get type(): string {
    return this._type;
  }

  public get version(): number {
    return this._version;
  }

  public constructor(id: string, characterization: string, markup: string, question: string, score: number, type: string, version: number) {
    this._id = id;
    this._characterization = characterization;
    this._markup = markup;
    this._question = question;
    this._score = score;
    this._type = type;
    this._version = version;
  }
}
