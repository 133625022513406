import * as Contract from '@tableau/api-external-contract-js';
import { ExecuteParameters, ParameterId, VerbId, VisualId, VisualSpecification } from '@tableau/api-internal-contract-js';
import { ServiceNames } from '../ServiceRegistry';
import { VisualModelService } from '../VisualModelService';
import { ServiceImplBase } from './ServiceImplBase';

export class VisualModelServiceImpl extends ServiceImplBase implements VisualModelService {
  public get serviceName(): string {
    return ServiceNames.VisualModel;
  }

  public getVisualSpecificationAsync(visualId: VisualId): Promise<Contract.VisualSpecification> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'getVisualSpecificationAsync',
      [ParameterId.WorksheetName]: visualId.worksheet,
    };

    return this.execute(VerbId.GetVisualSpecification, parameters).then<Contract.VisualSpecification>((response) => {
      const result = response.result as VisualSpecification;
      return result;
    });
  }

  public addMarksCardFieldsAsync(
    visualId: VisualId,
    marksCardIndex: number,
    encodingType: Contract.EncodingType,
    columns: Array<Contract.Column>,
    startIndex: number,
  ): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'addMarksCardFieldsAsync',
      [ParameterId.WorksheetName]: visualId.worksheet,
      [ParameterId.MarksCardIndex]: marksCardIndex,
      [ParameterId.EncodingType]: encodingType,
      [ParameterId.Columns]: columns,
      [ParameterId.StartIndex]: startIndex,
    };
    return this.execute(VerbId.AddMarksCardFields, parameters).then(() => {
      return;
    });
  }

  public moveMarksCardFieldAsync(
    visualId: VisualId,
    marksCardIndex: number,
    fromIndex: number,
    toIndex: number,
    fieldCount = 1,
  ): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'addMarksCardFieldsAsync',
      [ParameterId.WorksheetName]: visualId.worksheet,
      [ParameterId.MarksCardIndex]: marksCardIndex,
      [ParameterId.FromIndex]: fromIndex,
      [ParameterId.ToIndex]: toIndex,
      [ParameterId.FieldCount]: fieldCount,
    };
    return this.execute(VerbId.MoveMarksCardField, parameters).then(() => {
      return;
    });
  }

  public spliceMarksCardFieldsAsync(
    visualId: VisualId,
    marksCardIndex: number,
    encodingType: Contract.EncodingType,
    startIndex: number,
    deleteCount: number,
    columns: Array<Contract.Column>,
  ): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'spliceMarksCardFieldsAsync',
      [ParameterId.WorksheetName]: visualId.worksheet,
      [ParameterId.MarksCardIndex]: marksCardIndex,
      [ParameterId.EncodingType]: encodingType,
      [ParameterId.StartIndex]: startIndex,
      [ParameterId.DeleteCount]: deleteCount,
      [ParameterId.Columns]: columns,
    };
    return this.execute(VerbId.SpliceMarksCardFields, parameters).then(() => {
      return;
    });
  }

  editAliasesDialogAsync(fieldName: string): Promise<void> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'editAliasesDialogAsync',
      [ParameterId.FieldId]: fieldName,
    };
    return this.execute(VerbId.EditAliasesDialog, parameters).then(() => {
      return;
    });
  }
}
