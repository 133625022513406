import {
  DashboardObjectPositionAndSizeUpdate,
  ExportDataOptions,
  ExportScenariosForPDFAndPowerPoint,
  ExportPDFOptions as ExternalExportPDFOptions,
  PrintOrientation,
  PrintPageSize,
  PrintScaling,
  SharedErrorCodes,
} from '@tableau/api-external-contract-js';
import { DashboardObjectImpl } from '../Impl/DashboardObjectImpl';
import { TableauError } from '../TableauError';
import { Param } from './Param';

interface EnumLike {
  toString(): string;
}

/**
 * This class is used to construct common errors throughout the external
 * projects (api-shared, extensions-api, etc.).  It has some duplication with
 * the ErrorHelpers class in api-core, but is separate due to the need to throw
 * an external TableauError vs. an InternalTableauError.
 */
export class ErrorHelpers {
  /**
   * Throws with code InternalError.
   *
   * @param apiName name of api that was called.
   */
  public static apiNotImplemented(apiName: string): TableauError {
    return new TableauError(SharedErrorCodes.InternalError, `${apiName} API not yet implemented.`);
  }

  /**
   * Throws an internal error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyInternalValue(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(SharedErrorCodes.InternalError, `${argumentValue} is invalid value for: ${argumentName}`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyParameter(argumentValue: any, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} is invalid value for parameter: ${argumentName}`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is not the specified type.
   * For objects, it just tests that it is an object
   *
   * @param argumentValue value to verify
   * @param expectedType expected result of typeof
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyParameterType(argumentValue: any, expectedType: string, argumentName: string) {
    if (typeof argumentValue !== expectedType) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} has invalid type for parameter: ${argumentName}.`);
    }
  }

  /**
   * Throws an InvalidParameter error if argument is empty string, null or undefined.
   *
   * @param argumentValue value to verify
   * @param argumentName name of argument to verify
   */
  /*tslint:disable-next-line */
  public static verifyStringParameter(argumentValue: string, argumentName: string) {
    if (argumentValue === null || argumentValue === undefined || argumentValue === '') {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${argumentValue} is invalid value for paramter: ${argumentName}`);
    }
  }

  /**
   * Verifies passed value is a valid value for that enum.
   *
   * String enums are {string : string} dictionaries which are not reverse mappable
   * This is an ugly workaround
   *
   * @param enumValue value to verify
   * @param enumType enum to verify against
   */
  /*tslint:disable-next-line */
  public static isValidEnumValue<EnumType extends EnumLike>(enumValue: EnumType, enumType: any): boolean {
    let isValid = false;
    Object.keys(enumType).forEach((enumKey) => {
      if (enumType[enumKey] === enumValue.toString()) {
        isValid = true;
      }
    });

    return isValid;
  }

  /**
   * Verifies passed value is a valid value for that enum.
   * Throws an InvalidParameter error if the enum value is not valid.
   *
   * String enums are {string : string} dictionaries which are not reverse mappable
   * This is an ugly workaround
   *
   * @param enumValue value to verify
   * @param enumType enum to verify against
   * @param enumName enum name for clear error message
   */
  /*tslint:disable-next-line */
  public static verifyEnumValue<EnumType extends EnumLike>(enumValue: EnumType, enumType: any, enumName: string) {
    if (!ErrorHelpers.isValidEnumValue(enumValue, enumType)) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${enumValue} is invalid value for enum: ${enumName}.`);
    }
  }

  /**
   * Verifies passed value is between [min, max) ... min <= value < max
   * Throws an InvalidParameter error if the value is not valid.
   *
   *
   * @param value value to verify
   * @param min   value must be >= min
   * @param max   value must be < max
   */
  /*tslint:disable-next-line */
  public static verifyRange(value: number, min: number, max: number) {
    let isValid = min <= value && value < max;

    if (!isValid) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, `${value} is invalid value for range: [${min}..${max})`);
    }
  }

  /**
   * Verifies the params min and max for applying range filter.
   * Throws with error code InvalidParameter if range is invalid.
   *
   * @param min range min
   * @param max range max
   */
  /*tslint:disable-next-line */
  public static verifyRangeParamType(min: any, max: any): void {
    if (!min && !max) {
      throw new TableauError(SharedErrorCodes.InvalidParameter, 'Unexpected invalid param value, at least one of min or max is required.');
    }

    if (min && !Param.isTypeNumber(min) && !Param.isTypeDate(min)) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter min.',
      );
    }

    if (max && !Param.isTypeNumber(max) && !Param.isTypeDate(max)) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, only Date and number are allowed for parameter max.',
      );
    }

    if (min && max && typeof min !== typeof max) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        'Unexpected invalid param value, parameters min and max should be of the same type.',
      );
    }
  }

  /**
   * Verifies that the zoneId is present in the current dashboard.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardZoneMap A map of zoneId's to the corresponding dashboard object.
   * @param zoneID ZoneId to be validated
   */
  public static verifyZoneIsValid(dashboardZoneMap: Map<number, DashboardObjectImpl>, zoneID: number): void {
    if (dashboardZoneMap.has(zoneID)) {
      return;
    }

    throw new TableauError(
      SharedErrorCodes.InvalidParameter,
      `Unexpected invalid param value, Dashboard Object Id: ${zoneID} is not present in dashboard.`,
    );
  }

  /**
   * Verifies that the zone is present and floating in the current dashboard.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardZoneMap A map of zoneId's to the corresponding dashboard object.
   * @param zoneID ZoneId to be validated
   */
  public static verifyZoneIsValidAndFloating(dashboardZoneMap: Map<number, DashboardObjectImpl>, zoneID: number): void {
    if (dashboardZoneMap.has(zoneID) && dashboardZoneMap.get(zoneID)!.isFloating) {
      return;
    }

    throw new TableauError(
      SharedErrorCodes.InvalidParameter,
      `Unexpected invalid param value, Dashboard Object Id: ${zoneID} is not present or is a fixed zone in the dashboard.`,
    );
  }

  /**
   * Verifies that width and height are > 0 for each DashboardObjectPositionAndSizeUpdate object.
   * Throws with error code InvalidParameter if either condition is false.
   *
   * @param dashboardObjectPositionAndSizeUpdate DashboardObjectPositionAndSizeUpdate object for which width and height will be validated
   */
  public static verifyWidthAndHeightOfDashboardObjectPositionAndSizeUpdate(
    dashboardObjectPositionAndSizeUpdate: DashboardObjectPositionAndSizeUpdate,
  ): void {
    if (dashboardObjectPositionAndSizeUpdate.width < 0 || dashboardObjectPositionAndSizeUpdate.height < 0) {
      throw new TableauError(
        SharedErrorCodes.InvalidParameter,
        `Unexpected invalid param value for dashboard object ID ${dashboardObjectPositionAndSizeUpdate.dashboardObjectID}:` +
          ` negative widths and heights are not allowed.`,
      );
    }
  }

  /**
   * Verifies is the given sheet name is in the list of sheets names allowed for exporting.
   * Throws with error code InvalidParameter if the condition above is false.
   *
   * @param exportableSheetNames A list of sheet names allowed for exporting
   * @param sheetName the name of the sheet selected for export
   */
  public static verifySheetName(exportableSheetNames: Array<string>, sheetName: string) {
    if (!exportableSheetNames.includes(sheetName)) {
      throw new TableauError(
        SharedErrorCodes.InvalidSelectionSheet,
        'sheetName parameter must belong to a worksheet within the current view',
      );
    }
  }

  /**
   * Verifies the ExportDataOptions object
   * Throws with error code InvalidParameter if the parameter is not of ExportDataOptions type.
   *
   * @param options The ExportDataOptions object used to configure the output CSV file for exportDataAsync.
   */
  public static verifyExportDataOptions(options: ExportDataOptions): void {
    if (
      (!Param.isNullOrUndefined(options.ignoreAliases) && !Param.isTypeBool(options.ignoreAliases)) ||
      (!Param.isNullOrUndefined(options.columnsToIncludeById) && !Array.isArray(options.columnsToIncludeById))
    )
      throw new TableauError(SharedErrorCodes.InvalidParameter, 'options paramater must be of type ExportDataOptions');
  }

  /**
   * Verifies if the sheet names are included in any of the export scenarios.
   * Throws with error code InvalidParameter if the parameter is not an array.
   * Throws with error code InvalidSelectionSheet if there are array entries that dont exist or if there is mixing between sheets from the dashboard and sheet from the workbook.
   *
   * @param sheetNames List of sheet names selected for exporting.
   * @param exportScenarios an object containing the current sheet name, exportable sheets from the dashboard, and exportable sheets from the workbook.
   */
  public static verifySheetNamesForPDFAndPPT(sheetNames: Array<string>, exportScenarios: ExportScenariosForPDFAndPowerPoint): void {
    if (!Array.isArray(sheetNames)) throw new TableauError(SharedErrorCodes.InvalidParameter, 'sheetNames parameter must be an array');
    if (sheetNames.length === 0) throw new TableauError(SharedErrorCodes.InternalError, 'sheetNames should not be empty');

    const isIncludedInDashboard = sheetNames.every((sheetName: string) =>
      exportScenarios.exportableSheetNamesFromDashboard.includes(sheetName),
    );
    if (isIncludedInDashboard) {
      return;
    }
    const isIncludedInWorkbook = sheetNames.every((sheetName: string) =>
      exportScenarios.exportableSheetNamesFromWorkbook.includes(sheetName),
    );
    if (isIncludedInWorkbook) {
      return;
    }
    const isCurrentSheet = sheetNames.length === 1 && sheetNames[0] === exportScenarios.currentSheetName;
    if (isCurrentSheet) {
      return;
    }

    // Export requirement: all sheet names must be included in the list of exportable sheets in the dashboard or
    // all sheet names must be included in the list of exportable sheets in the workbook, or the sheet name is the current sheet
    throw new TableauError(
      SharedErrorCodes.InvalidSelectionSheet,
      'sheetNames parameter must have all its entries be exportable sheet names from the dashboard or all its entries be exportable sheet names from the workbook',
    );
  }

  /**
   * Verifies the external ExportPDFOptions object's properties.
   * Throws with error code InvalidParameter if the parameter is not of external ExportPDFOptions type.
   *
   * @param options The external user-facing ExportPDFOptions object used to configure the output PDF file for exportPDFAsync.
   */
  public static verifyExportPDFOptions(options: ExternalExportPDFOptions) {
    try {
      this.verifyEnumValue<PrintOrientation>(options.orientation, PrintOrientation, 'PrintOrientation');
      this.verifyEnumValue<PrintPageSize>(options.pageSize, PrintPageSize, 'PrintPageSize');
      this.verifyEnumValue<PrintScaling>(options.scaling, PrintScaling, 'PrintScaling');
    } catch {
      throw new TableauError(SharedErrorCodes.InvalidParameter, 'options parameter must be of type ExportPDFOptions');
    }
  }
}
