import * as Contract from '@tableau/api-external-contract-js';
import {
  CrossFrameMessenger,
  EmbeddingPulseBootstrapInfo,
  FirstPulseMetricSizeKnownEvent as FirstPulseMetricSizeKnownModel,
  InternalApiDispatcher,
  NotificationId,
  PulseErrorEvent as PulseErrorModel,
  PulseFiltersChangedEvent as PulseFiltersChangedModel,
  PulseInsightDiscoveredEvent as PulseInsightDiscoveredModel,
  PulseTimeDimensionChangedEvent as PulseTimeDimensionChangedModel,
  PulseUrlChangedEvent as PulseUrlChangedModel,
} from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  CrossFrameDispatcher,
  NotificationService,
  NotificationServiceImpl,
  ServiceNames,
  TableauError,
} from '@tableau/api-shared-js';
import { TableauPulse } from '../Components/TableauPulse';
import { FirstPulseMetricSizeKnownEvent } from '../Events/FirstPulseMetricSizeKnownEvent';
import { PulseErrorEvent } from '../Events/PulseErrorEvent';
import { PulseFiltersChangedEvent } from '../Events/PulseFiltersChangedEvent';
import { PulseInsightDiscoveredEvent } from '../Events/PulseInsightDiscoveredEvent';
import { PulseTimeDimensionChangedEvent } from '../Events/PulseTimeDimensionChangedEvent';
import { PulseUrlChangedEvent } from '../Events/PulseUrlChangedEvent';
import { EmbeddingServiceNames, registerInitializationEmbeddingServices } from '../Services';
import { PulseServiceImpl } from '../Services/Impl/PulseServiceImpl';
import { PulseService } from '../Services/PulseService';
import { HtmlElementHelpers } from '../Utils/HtmlElementHelpers';

export class PulseImpl {
  private _dispatcher: InternalApiDispatcher;
  private _messenger: CrossFrameMessenger;
  private readonly _resizeEventType = 'resize';
  private _windowResizeHandler?: () => void;
  private _shouldDispatchMetricSizeKnownEvent: boolean;

  public constructor(
    private _pulse: TableauPulse,
    private _iframe: HTMLIFrameElement,
    private _frameUrl: URL,
    private _timeDimension: Contract.PulseTimeDimension | undefined,
    private _filters: Array<Contract.FilterParameters>,
    private _embeddingId: number,
  ) {
    if (!this._iframe) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
    }

    // When an initial time dimension or filters are provided, we delay the dispatching of the FirstPulseMetricSizeKnownEvent
    // until after they've been applied and the PulseFirstInteractive event of the ultimate metric fires.
    // If no time dimension or filters are provided, we dispatch the event normally.
    this._shouldDispatchMetricSizeKnownEvent = !_timeDimension && !_filters.length;
  }

  public get iframe(): HTMLIFrameElement {
    return this._iframe;
  }

  public get embeddingId(): number {
    return this._embeddingId;
  }

  public initialize(): void {
    const iframeWindow = this._iframe.contentWindow;
    if (!iframeWindow) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Iframe has not been created yet');
    }
    try {
      this._messenger = new CrossFrameMessenger(window, iframeWindow, this._frameUrl.origin);
      this._dispatcher = new CrossFrameDispatcher(this._messenger);

      registerInitializationEmbeddingServices(this._dispatcher, this.embeddingId);

      const initializationService = ApiServiceRegistry.get(this.embeddingId).getService<NotificationService>(ServiceNames.Initialization);
      const pulseMetricSizeKnownUnregister = initializationService.registerHandler(
        NotificationId.FirstPulseMetricSizeKnown,
        () => this._shouldDispatchMetricSizeKnownEvent,
        (model: FirstPulseMetricSizeKnownModel) => {
          this.handlePulseMetricSizeKnownEvent(model);
          pulseMetricSizeKnownUnregister();
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseInteractive,
        () => true,
        (model: EmbeddingPulseBootstrapInfo) => {
          this.handlePulseInteractiveEvent(model);
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseError,
        () => true,
        (model: PulseErrorModel) => {
          this.handlePulseErrorEvent(model);
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseUrlChanged,
        () => true,
        (model: PulseUrlChangedModel) => {
          this.handlePulseUrlChangedEvent(model);
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseTimeDimensionChanged,
        () => true,
        (model: PulseTimeDimensionChangedModel) => {
          this.handlePulseTimeDimensionChangedEvent(model);
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseInsightDiscovered,
        () => true,
        (model: PulseInsightDiscoveredModel) => {
          this.handlePulseInsightDiscoveredEvent(model);
        },
      );
      initializationService.registerHandler(
        NotificationId.PulseFiltersChanged,
        () => true,
        (model: PulseFiltersChangedModel) => {
          this.handlePulseFiltersChangedEvent(model);
        },
      );

      this._messenger.startListening();
    } catch (e) {
      throw new TableauError(Contract.EmbeddingErrorCodes.InternalError, 'Unexpected error during initialization.');
    }
  }

  public dispose(): void {
    if (this._messenger) {
      this._messenger.stopListening();
    }

    this.removeWindowResizeHandler();
  }

  private updateIframeTitle(bootstrapInfo: EmbeddingPulseBootstrapInfo): void {
    this._iframe.setAttribute('title', bootstrapInfo.iframeTitle);
  }

  private handlePulseMetricSizeKnownEvent(model: FirstPulseMetricSizeKnownModel): void {
    const sizeEvent = new FirstPulseMetricSizeKnownEvent(model.width, model.height);
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstPulseMetricSizeKnown, { detail: sizeEvent }));

    if (this._pulse.fixedSize) {
      return;
    }

    this.resize();
    this.addWindowResizeHandler();
  }

  public resize(): void {
    const { height, width } = this.calculateLayoutSize();

    this._iframe.style.height = height + 'px';
    this._iframe.style.width = width + 'px';
  }

  private calculateLayoutSize(): { height: number; width: number } {
    const availableSize = this._pulse.parentElement
      ? HtmlElementHelpers.getContentSize(this._pulse.parentElement)
      : { height: 0, width: 0 };

    return availableSize;
  }

  private removeWindowResizeHandler(): void {
    if (!this._windowResizeHandler) {
      return;
    }

    window.removeEventListener(this._resizeEventType, this._windowResizeHandler);
  }

  private addWindowResizeHandler(): void {
    if (this._windowResizeHandler) {
      return;
    }

    this._windowResizeHandler = this.resize.bind(this);
    window.addEventListener(this._resizeEventType, this._windowResizeHandler!);
  }

  private handlePulseInteractiveEvent(bootstrapInfo: EmbeddingPulseBootstrapInfo): void {
    ApiServiceRegistry.get(this.embeddingId).registerService(new PulseServiceImpl(this._dispatcher, this.embeddingId));
    ApiServiceRegistry.get(this.embeddingId).registerService(new NotificationServiceImpl(this._dispatcher));

    if (this._timeDimension) {
      this.applyTimeDimensionAsync(this._timeDimension);

      // Applying the time dimension will trigger a new interactive event, so we need to bail here.
      // Time dimension is cleared so it doesn't try to apply again during the next interactive event.
      this._timeDimension = undefined;
      return;
    }

    if (this._filters.length) {
      this.applyFiltersAsync(
        this._filters.map((f) => ({
          fieldName: f.field,
          values: f.value.split(','),
          updateType: Contract.FilterUpdateType.Replace,
          options: { isExcludeMode: false },
        })),
      );

      // Applying the filters will trigger a new interactive event, so we need to bail here.
      // Filter list is cleared so they don't try to apply again during the next interactive event.
      this._filters = [];
      return;
    }

    this._iframe.style.visibility = 'visible';
    this.updateIframeTitle(bootstrapInfo);

    this._shouldDispatchMetricSizeKnownEvent = true;
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.FirstInteractive));
  }

  private handlePulseErrorEvent(model: PulseErrorModel) {
    const event = new PulseErrorEvent(model.message, model.httpStatus, model.messageVisibility);
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.PulseError, { detail: event }));
  }

  private handlePulseUrlChangedEvent(model: PulseUrlChangedModel) {
    const event = new PulseUrlChangedEvent(model.oldUrl, model.newUrl, model.context);
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.PulseUrlChanged, { detail: event }));
  }

  private handlePulseTimeDimensionChangedEvent(model: PulseTimeDimensionChangedModel) {
    const event = new PulseTimeDimensionChangedEvent(model.timeDimension);
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.PulseTimeDimensionChanged, { detail: event }));
  }

  private handlePulseInsightDiscoveredEvent(model: PulseInsightDiscoveredModel) {
    const event = new PulseInsightDiscoveredEvent(
      model.id,
      model.characterization,
      model.markup,
      model.question,
      model.score,
      model.type,
      model.version,
    );

    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.PulseInsightDiscovered, { detail: event }));
  }

  private handlePulseFiltersChangedEvent(model: PulseFiltersChangedModel) {
    const event = new PulseFiltersChangedEvent(model.fieldNames, this.embeddingId);
    this._pulse.dispatchEvent(new CustomEvent(Contract.EmbeddingTableauEventType.PulseFiltersChanged, { detail: event }));
  }

  public applyFiltersAsync(
    filters: Array<{
      fieldName: string;
      values: Contract.PulseFieldValueArray;
      updateType: Contract.FilterUpdateType;
      options: Contract.FilterOptions;
    }>,
  ): Promise<Array<string>> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.applyFiltersAsync(filters);
  }

  public getTimeDimensionAsync(): Promise<Contract.PulseTimeDimension> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.getTimeDimensionAsync();
  }

  public applyTimeDimensionAsync(timeDimension: Contract.PulseTimeDimension): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.applyTimeDimensionAsync(timeDimension);
  }

  public getFiltersAsync(): Promise<Array<Contract.PulseFilter>> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.getFiltersAsync();
  }

  public clearFiltersAsync(fieldNames: Array<string>): Promise<Array<string>> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.clearFiltersAsync(fieldNames);
  }

  public clearAllFiltersAsync(): Promise<void> {
    const service = ApiServiceRegistry.get(this.embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.clearAllFiltersAsync();
  }
}
