import {
  EmbeddingTableauEventType,
  FilterOptions,
  FilterParameters,
  FilterUpdateType,
  Pulse,
  PulseAttributes,
  PulseChildElementAttributes,
  PulseChildElements,
  PulseFieldValueArray,
  PulseFilter,
  PulseLayout,
  PulseSettings,
  PulseTheme,
  PulseThemeProperty,
  PulseTimeDimension,
} from '@tableau/api-external-contract-js';
import { ErrorHelpers } from '@tableau/api-shared-js';
import { PulseImpl } from '../Impl/PulseImpl';
import { createPulseUrl } from '../Models/EmbeddingPulseUrl';
import { getSiteIdForPulse } from '../Models/EmbeddingUrlBuilder';
import { WebComponentManager } from '../WebComponentManager';
import { attributeToEnumKey } from './TableauVizBase';
import { TableauAuthResponse, TableauWebComponent } from './TableauWebComponent';

type AttributeEventType = [PulseAttributes, EmbeddingTableauEventType];

/**
 * Represents the entry point for the `<tableau-pulse>` custom HTML element.
 * This class is specifically focused on transferring information between the HTML and
 * the Tableau Pulse, so it should have as little logic as possible.
 */
export class TableauPulse extends TableauWebComponent implements Pulse {
  private _pulseImpl: PulseImpl;

  // This stores filters added via addFilter()
  private _preInitFilters: Array<FilterParameters> = [];

  public constructor() {
    super();
  }

  public disconnectedCallback(): void {
    super.disconnectedCallback();
    this._preInitFilters = [];
    if (this._pulseImpl) {
      this._pulseImpl.dispose();
    }
  }

  private getAttributeEvents(): AttributeEventType[] {
    return [
      [PulseAttributes.OnFirstInteractive, EmbeddingTableauEventType.FirstInteractive],
      [PulseAttributes.OnFirstPulseMetricSizeKnown, EmbeddingTableauEventType.FirstPulseMetricSizeKnown],
      [PulseAttributes.OnPulseUrlError, EmbeddingTableauEventType.PulseError],
      [PulseAttributes.OnPulseFiltersChanged, EmbeddingTableauEventType.PulseFiltersChanged],
      [PulseAttributes.OnPulseInsightDiscovered, EmbeddingTableauEventType.PulseInsightDiscovered],
      [PulseAttributes.OnPulseTimeDimensionChanged, EmbeddingTableauEventType.PulseTimeDimensionChanged],
      [PulseAttributes.OnPulseUrlChanged, EmbeddingTableauEventType.PulseUrlChanged],
    ];
  }

  public static get observedAttributes(): string[] {
    // Take caution before adding to this list because for every observed attribute change
    // we unregister and re-render the ask-data webcomponent
    return [...super.observedAttributes, ...Object.values(PulseAttributes)];
  }

  protected async updateRenderingIfInitialized(src?: string): Promise<void> {
    if (!this._initialized) {
      return;
    }

    if (this._pulseImpl) {
      this._pulseImpl.dispose();
    }

    WebComponentManager.unregisterWebComponent(this._embeddingIdCounter);
    return this.updateRendering(src);
  }

  protected async updateRendering(src?: string): Promise<void> {
    try {
      this._initialized = true;
      if (!src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      if (!this.token && !this.isTokenOptional) {
        console.debug(`A token needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      const authResponse = await this.auth(getSiteIdForPulse(src));
      if (authResponse === TableauAuthResponse.Failure) {
        console.debug('Authentication failed.');
        return;
      }
      // Nothing to render if the user hasn't provided a src
      if (!this.src) {
        console.debug(`A src needs to be set on the ${this.tagName.toLowerCase()} element. Skipping rendering.`);
        return;
      }
      if (!this.iframe) {
        console.debug('No iframe available to update the src.');
        return;
      }
      const customParams = this.readCustomParamsFromChildren();
      this._embeddingIdCounter = WebComponentManager.registerWebComponent(this);
      this.registerAttributeEvents();
      const pulseUrl = createPulseUrl(this.src, this.constructOptions(), customParams);
      const filters = this.readFiltersFromChild().concat(this._preInitFilters);
      this._pulseImpl = this.createAndInitializePulseImpl(pulseUrl, filters);

      if (this.timeDimension || filters.length) {
        // If we have a time dimension or filters we hide the iframe until they are done applying to hide the reloads.
        // The first interactive event will re-show it once it fires.
        this.iframe.style.visibility = 'hidden';
      }

      this.iframe.src = pulseUrl.toString();
      this.raiseIframeSrcUpdatedNotification();
      return;
    } catch (e) {
      console.warn(e);
    }
  }

  private createAndInitializePulseImpl(pulseUrl: URL, filters: Array<FilterParameters>): PulseImpl {
    const pulseImpl = new PulseImpl(this, this.iframe, pulseUrl, this.timeDimension, filters, this._embeddingIdCounter);
    pulseImpl.initialize();
    return pulseImpl;
  }

  private registerAttributeEvents(): void {
    this.getAttributeEvents().forEach((elem) => {
      const [attributeEvent, eventType] = elem;
      this.registerCallback(attributeEvent, eventType);
    });
  }

  private readFiltersFromChild(): Array<FilterParameters> {
    const filters: Array<FilterParameters> = [];
    [].forEach.call(this.children, (child: HTMLElement) => {
      if (
        child.localName === PulseChildElements.PulseFilter &&
        child.getAttribute(PulseChildElementAttributes.Field) &&
        child.getAttribute(PulseChildElementAttributes.Value)
      ) {
        filters.push({
          field: child.getAttribute(PulseChildElementAttributes.Field)!,
          value: child.getAttribute(PulseChildElementAttributes.Value)!,
        });
      }
    });
    return filters;
  }

  private readThemeParametersFromChild(): PulseThemeProperty[] {
    const properties: PulseThemeProperty[] = [];
    [].forEach.call(this.children, (child: HTMLElement) => {
      if (child.localName === PulseChildElements.ThemeParameter) {
        const name = child.getAttribute(PulseChildElementAttributes.Name);
        const value = child.getAttribute(PulseChildElementAttributes.Value);
        const type = child.getAttribute(PulseChildElementAttributes.Type);
        if (name && value) {
          // type is optional
          properties.push({
            name,
            value,
            type,
          });
        }
      }
    });

    return properties;
  }

  private getThemeString(): string | undefined {
    const theme = this.themeObj;
    if (!theme) {
      return;
    }

    try {
      return btoa(JSON.stringify(theme));
    } catch {
      return;
    }
  }

  private constructOptions(): PulseSettings {
    const options: PulseSettings = {
      token: this.token,
      theme: this.getThemeString(),
    };
    if (this.disableExploreFilter) {
      options.disableExploreFilter = this.disableExploreFilter;
    }
    if (this.layout && this.layout !== PulseLayout.Default) {
      // An empty value implies a default Pulse layout. Don't pass "default" value.
      options.layout = this.layout;
    }
    return options;
  }

  public get disableExploreFilter(): boolean {
    return this.hasAttribute(PulseAttributes.DisableExploreFilter);
  }

  public set disableExploreFilter(v: boolean) {
    if (v) {
      this.setAttribute(PulseAttributes.DisableExploreFilter, '');
    } else {
      this.removeAttribute(PulseAttributes.DisableExploreFilter);
    }
  }

  public get layout(): PulseLayout {
    const layoutKey = attributeToEnumKey(this.getAttribute(PulseAttributes.Layout));
    const layout = PulseLayout[layoutKey];
    if (!layout) {
      return PulseLayout.Default;
    }

    return layout;
  }

  public set layout(v: PulseLayout) {
    if (v) {
      this.setAttribute(PulseAttributes.Layout, v);
    } else {
      this.removeAttribute(PulseAttributes.Layout);
    }
  }

  public get themeObj(): PulseTheme | undefined {
    let theme: PulseTheme | undefined;

    for (const { name, value, type } of this.readThemeParametersFromChild()) {
      theme = theme ?? {};

      if (type) {
        theme[type] = theme[type] ?? {};
        theme[type]![name] = value;
      } else {
        theme[name] = value;
      }
    }

    return theme;
  }

  public get timeDimension(): PulseTimeDimension | undefined {
    const key = this.getAttribute(PulseAttributes.TimeDimension) as PulseTimeDimension | undefined;
    if (!key) {
      return;
    }

    ErrorHelpers.verifyEnumValue<PulseTimeDimension>(key, PulseTimeDimension, 'Contract.PulseTimeDimension');
    return PulseTimeDimension[key];
  }

  public set timeDimension(v: PulseTimeDimension | undefined) {
    if (v) {
      ErrorHelpers.verifyEnumValue<PulseTimeDimension>(v, PulseTimeDimension, 'Contract.PulseTimeDimension');
      this.setAttribute(PulseAttributes.TimeDimension, v);
    } else {
      this.removeAttribute(PulseAttributes.TimeDimension);
    }
  }

  public get isTokenOptional(): boolean {
    return this.hasAttribute(PulseAttributes.TokenOptional);
  }

  public set isTokenOptional(v: boolean) {
    if (v) {
      this.setAttribute(PulseAttributes.TokenOptional, '');
    } else {
      this.removeAttribute(PulseAttributes.TokenOptional);
    }
  }

  public addFilter(fieldName: string, value: string): void {
    this._preInitFilters.push({ field: fieldName, value: value });
    WebComponentManager.synchronizeRender(this.updateRenderingIfInitialized.bind(this, this.src));
  }

  public resize(): void {
    this._pulseImpl.resize();
  }

  public applyFilterAsync(
    fieldName: string,
    values: PulseFieldValueArray,
    updateType: FilterUpdateType,
    options: FilterOptions,
  ): Promise<string> {
    return this.applyFiltersAsync([{ fieldName, values, updateType, options }]).then((fieldNames) => fieldNames[0]);
  }

  public applyFiltersAsync(
    filters: Array<{
      fieldName: string;
      values: PulseFieldValueArray;
      updateType: FilterUpdateType;
      options: FilterOptions;
    }>,
  ): Promise<Array<string>> {
    return this._pulseImpl.applyFiltersAsync(filters);
  }

  public getTimeDimensionAsync(): Promise<PulseTimeDimension> {
    return this._pulseImpl.getTimeDimensionAsync();
  }

  public applyTimeDimensionAsync(timeDimension: PulseTimeDimension): Promise<void> {
    return this._pulseImpl.applyTimeDimensionAsync(timeDimension);
  }

  public getFiltersAsync(): Promise<Array<PulseFilter>> {
    return this._pulseImpl.getFiltersAsync();
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this.clearFiltersAsync([fieldName]).then((fieldNames) => fieldNames[0]);
  }

  public clearFiltersAsync(fieldNames: Array<string>): Promise<Array<string>> {
    return this._pulseImpl.clearFiltersAsync(fieldNames);
  }

  public clearAllFiltersAsync(): Promise<void> {
    return this._pulseImpl.clearAllFiltersAsync();
  }
}
