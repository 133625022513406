// PLEASE KEEP IN ALPHABETICAL ORDER. This helps with visually finding entries, knowing where to put
// new ones, and merge conflicts.
export enum ParameterId {
  ActiveTablesInfo = 'active-tables-info',
  AnchorDate = 'anchor-date',
  AnnotateEnum = 'annotate-enum',
  AnnotationList = 'annotation-list',
  ApplyWorksheetFormatting = 'apply-worksheet-formatting',

  CategoricalDomain = 'categorical-domain',
  ClickThroughEnabled = 'click-through-enabled',
  Columns = 'columns',
  ColumnsToInclude = 'columns-to-include',
  ColumnsToIncludeById = 'columns-to-include-by-id',
  ConnectionDescriptionSummaries = 'connection-description-summaries',
  CurrentSheetType = 'current-sheet-type',
  CustomView = 'custom-view',
  CustomViewIsDefault = 'custom-view-is-default',
  CustomViewIsPublic = 'custom-view-is-public',
  CustomViewLuid = 'custom-view-luid',
  CustomViewName = 'custom-view-name',
  CustomViews = 'custom-views',

  Dashboard = 'dashboard',
  DashboardFilters = 'dashboard-filters',
  DashboardName = 'dashboard',
  DashboardObjectPositionAndSizeUpdate = 'dashboard-object-position-and-size-update',
  DashboardObjectPositionAndSizeUpdateList = 'dashboard-object-position-and-size-update-list',
  DataRowIndex = 'data-row-index',
  DataSchema = 'data-schema',
  DataSource = 'data-source',
  DataSourceDataTable = 'data-source-data-table',
  DataSourceId = 'data-source-id',
  DataSourceName = 'data-source-name',
  DataTable = 'data-table',
  DataTableReader = 'data-table-reader',
  DateRangeType = 'date-range-type',
  DeleteCount = 'delete-count',
  DeltaTimeMs = 'delta-time-ms',
  DimValSelectionModels = 'dimensionValueSelectionModels',
  DomainType = 'domain-type',

  EmbeddingAbstractQuery = 'embedding-abstract-query',
  EmbeddingBootstrapInfo = 'embedding-bootstrap-info',
  EncodingType = 'encoding-type',
  ExportCrosstabSheetMap = 'export-crosstab-sheet-map',
  ExportFile = 'export-file',
  ExportOriginUrl = 'export-origin-url',
  ExportPdfOptions = 'export-pdf-options',
  ExtensionBootstrapInfo = 'extension-bootstrap-info',
  ExtensionContextMenuIds = 'extension-context-menu-ids',
  ExtensionDialogH = 'extension-dialog-height',
  ExtensionDialogPayload = 'extension-dialog-payload',
  ExtensionDialogResult = 'extension-dialog-result',
  ExtensionDialogStyle = 'extension-dialog-style',
  ExtensionDialogUrl = 'extension-dialog-url',
  ExtensionDialogW = 'extension-dialog-width',
  ExtensionLocator = 'extension-locator',
  ExtensionSettingsInfo = 'extension-settings-info',
  ExtensionZoneId = 'extension-zone-id',
  ExternalMenuDescription = 'external-menu-description',
  ExternalMenuHeader = 'external-menu-header',
  ExternalMenuItemDisplayName = 'external-menu-item-display-name',
  ExternalMenuItemId = 'external-menu-item-id',

  Field = 'field',
  FieldCount = 'field-count',
  FieldId = 'field-id',
  FieldName = 'field-name',
  FilterLevels = 'filter-levels',
  FilterRangeMax = 'filter-range-max',
  FilterRangeMin = 'filter-range-min',
  FilterRangeNullOption = 'filter-range-null-option',
  FilterUpdateType = 'filter-update-type',
  FilterValues = 'filter-values',
  FontNameListItems = 'font-name-list-items',
  FormattedText = 'formatted-text',
  FunctionName = 'function-name',
  FromIndex = 'from-index',

  HierValSelectionModels = 'hierarchicalValueSelectionModels',
  HighlightedData = 'highlighted-data',
  HoverTupleInteraction = 'hover-tuple-interaction',

  IgnoreAliases = 'ignore-aliases',
  IgnoreSelection = 'ignore-selection',
  IncludeAllColumns = 'include-all-columns',
  InvalidAggFieldName = 'invalid-agg-field-name',
  InvalidDates = 'invalid-dates',
  InvalidFieldCaption = 'invalid-field-caption',
  InvalidFields = 'invalid-fields',
  InvalidValues = 'invalid-values',
  IsExcludeMode = 'is-exclude',
  IsExtensionDialog = 'is-extension-dialog',

  JoinDescription = 'join-description',

  LogicalTableId = 'logical-table-id',
  LogicalTableInfoList = 'logical-table-info-list',

  MarksCardIndex = 'marks-card-index',
  MaxHeight = 'max-height',
  MaxRows = 'max-rows',
  MaxWidth = 'max-width',
  MinHeight = 'min-height',
  MinWidth = 'min-width',

  PageRowCount = 'page-row-count',
  ParameterCaption = 'paremeter-caption',
  ParameterError = 'parameter-error',
  ParameterFieldName = 'parameter-field-name',
  ParameterInfo = 'parameter-info',
  ParameterInfos = 'parameter-infos',
  ParameterValue = 'parameter-value',
  PeriodType = 'period-type',
  PulseFieldName = 'fieldName',
  PulseFieldNames = 'fieldNames',
  PulseFilterDomainType = 'domainType',
  PulseFilterDomainSearchTerm = 'searchTerm',
  PulsePageSize = 'pageSize',
  PulseNextPageToken = 'nextPageToken',
  PulseFilters = 'filters',
  PulseTimeDimension = 'timeDimension',

  QuantitativeDomain = 'quantitative-dmain',
  QuantRangeSelectionModels = 'quantativeRangeSelectionModels',

  RangeN = 'range-n',
  ReplaySpeed = 'replay-speed',

  SelectedData = 'selected-data',
  SelectedSheetNames = 'selected-sheet-names',
  Selection = 'selection',
  SelectionList = 'selection-list',
  SelectionUpdateType = 'selectionUpdateType',
  SelectTuplesInteraction = 'select-tuples-interaction',
  SendNotifications = 'send-notifications',
  SettingsValues = 'settings-values',
  SharedFilterSheets = 'shared-filter-sheets',
  SharedFiltersInfo = 'shared-filters-info',
  SheetIdentifier = 'sheet-identifier',
  SheetName = 'sheet-name',
  SheetPath = 'sheet-path',
  ShouldRefreshDS = 'should-refresh-ds',
  ShowDataTableFormat = 'show-data-table-format',
  StartIndex = 'start-index',
  State = 'state',
  StoryPointIndex = 'story-point-index',
  StoryPointInfo = 'story-point-info',
  SwitchToSheetName = 'switch-to-sheet-name',

  TargetPoint = 'target-point',
  TestConversionParameter = 'test-conversion-parameter',
  Text = 'formatted-text',
  ToIndex = 'to-index',
  TooltipContext = 'tooltip-context',
  TooltipTextRequest = 'tooltip-text-request',
  TooltipText = 'tooltip-text',

  UnderlyingDataTable = 'underlying-data-table',
  UnderlyingSummaryDataTable = 'underlying-summary-data-table',
  Url = 'url',
  UseTabDelimiters = 'use-tab-delimiters',

  ViewDataTableCacheId = 'view-data-table-cache-id',
  ViewDataTableId = 'view-data-table-id',
  VisualId = 'visual-id',
  VisualSpecification = 'visual-specification',
  VizAPIInputJson = 'vizapi-input-json',
  VizAPIStateWithDataModel = 'vizapi-state-with-data-model',
  VizAPIStateWarningMsg = 'vizapi-state-warning-msg',
  VizAPISVG = 'vizapi-svg',

  WorksheetName = 'worksheet-name',
  WorksheetFilters = 'worksheet-filters',

  ZoneIdsVisibilityMap = 'zone-ids-visibility-map',
}
