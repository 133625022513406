import {
  FilterDomainType,
  FilterOptions,
  FilterType,
  PulseCategoricalDomain,
  PulseFilter,
  PulseTimeDimension,
} from '@tableau/api-external-contract-js';
import { ExecuteParameters, FilterUpdateType, ParameterId, PulseFieldValueArray, VerbId } from '@tableau/api-internal-contract-js';
import { ErrorHelpers, ServiceImplBase, TableauError } from '@tableau/api-shared-js';
import { ErrorCodes } from '../../../EmbeddingApi';
import { CategoricalPulseFilter } from '../../Models/PulseFilter';
import { EmbeddingServiceNames } from '../EmbeddingServiceNames';
import { PulseService } from '../PulseService';

export class PulseServiceImpl extends ServiceImplBase implements PulseService {
  public get serviceName(): string {
    return EmbeddingServiceNames.PulseService;
  }

  public applyFiltersAsync(
    filters: Array<{
      fieldName: string;
      values: PulseFieldValueArray;
      updateType: FilterUpdateType;
      options: FilterOptions;
    }>,
  ): Promise<Array<string>> {
    const verb = VerbId.ApplyPulseFilters;
    const parameters: ExecuteParameters = {
      [ParameterId.PulseFilters]: filters,
    };

    if (!Array.isArray(filters)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'filters parameter for applyFiltersAsync must be an array');
    }

    for (const filter of filters) {
      if (!Array.isArray(filter.values)) {
        throw new TableauError(ErrorCodes.InvalidParameter, 'filter values for applyFiltersAsync must be an array');
      }

      if (!filter.updateType || !Object.values(FilterUpdateType).includes(filter.updateType)) {
        throw new TableauError(ErrorCodes.InvalidParameter, 'filter updateType for applyFiltersAsync must be a valid update type');
      }

      if (!filter.options) {
        throw new TableauError(ErrorCodes.InvalidParameter, 'filter options object for applyFiltersAsync must be provided');
      }

      if (![true, false].includes(filter.options.isExcludeMode)) {
        throw new TableauError(ErrorCodes.InvalidParameter, 'filter options.isExcludeMode for applyFiltersAsync must be a boolean');
      }
    }

    return this.execute(verb, parameters).then<Array<string>>((_) => {
      return filters.map((f) => f.fieldName);
    });
  }

  public getTimeDimensionAsync(): Promise<PulseTimeDimension> {
    const verb = VerbId.GetPulseTimeDimension;
    const parameters: ExecuteParameters = {};

    return this.execute(verb, parameters).then<PulseTimeDimension>((response) => {
      const { timeDimension } = response.result as { timeDimension: PulseTimeDimension | undefined };
      if (!timeDimension) {
        throw new TableauError(ErrorCodes.InvalidTimeDimension, 'Unable to determine the currently applied time dimension.');
      }

      if (!ErrorHelpers.isValidEnumValue<PulseTimeDimension>(timeDimension, PulseTimeDimension)) {
        const sentences = [
          `The current time dimension is '${timeDimension}', which is not a known value of the Contract.PulseTimeDimension enum.`,
          'You may need to update your version of the Tableau Embedding API.',
          `Valid values are: ${Object.keys(PulseTimeDimension).join(', ')}`,
        ];
        console.warn(sentences.join(' '));
      }

      return timeDimension;
    });
  }

  public applyTimeDimensionAsync(timeDimension: PulseTimeDimension): Promise<void> {
    ErrorHelpers.verifyEnumValue<PulseTimeDimension>(timeDimension, PulseTimeDimension, 'Contract.PulseTimeDimension');

    const verb = VerbId.ApplyPulseTimeDimension;
    const parameters: ExecuteParameters = {
      [ParameterId.PulseTimeDimension]: timeDimension,
    };

    return this.execute(verb, parameters).then<void>((_) => {
      return;
    });
  }

  public getCategoricalDomainAsync(
    fieldName: string,
    domainType: FilterDomainType,
    searchTerm: string,
    pageSize: number | undefined,
    nextPageToken: string | undefined,
  ): Promise<PulseCategoricalDomain> {
    ErrorHelpers.verifyStringParameter(fieldName, 'fieldName');
    ErrorHelpers.verifyEnumValue<FilterDomainType>(domainType, FilterDomainType, 'Contract.FilterDomainType');

    const verb = VerbId.GetPulseCategoricalDomain;
    const parameters: ExecuteParameters = {
      [ParameterId.PulseFieldName]: fieldName,
      [ParameterId.PulseFilterDomainType]: domainType,
      [ParameterId.PulseFilterDomainSearchTerm]: searchTerm,
    };

    if (pageSize !== undefined) {
      parameters[ParameterId.PulsePageSize] = pageSize;
    }

    if (nextPageToken !== undefined) {
      parameters[ParameterId.PulseNextPageToken] = nextPageToken;
    }

    return this.execute(verb, parameters).then<PulseCategoricalDomain>((response) => {
      const domain = response.result as PulseCategoricalDomain;
      return domain;
    });
  }

  public getFiltersAsync(): Promise<Array<PulseFilter>> {
    const verb = VerbId.GetPulseFilters;
    const parameters: ExecuteParameters = {};

    return this.execute(verb, parameters).then<Array<PulseFilter>>((response) => {
      const filters = response.result as Array<PulseFilter>;
      return this.convertDomainFilters(filters);
    });
  }

  public clearFiltersAsync(fieldNames: Array<string>): Promise<Array<string>> {
    const verb = VerbId.ClearPulseFilters;
    const parameters: ExecuteParameters = {
      [ParameterId.PulseFieldNames]: fieldNames,
    };

    if (!Array.isArray(fieldNames)) {
      throw new TableauError(ErrorCodes.InvalidParameter, 'fieldNames parameter for clearFiltersAsync must be an array');
    }

    return this.execute(verb, parameters).then<Array<string>>((_) => {
      return fieldNames;
    });
  }

  public clearAllFiltersAsync(): Promise<void> {
    const verb = VerbId.ClearAllPulseFilters;
    const parameters: ExecuteParameters = {};

    return this.execute(verb, parameters).then<void>((_) => {
      return;
    });
  }

  private convertDomainFilters(domainFilters: Array<PulseFilter>): Array<PulseFilter> {
    const filters: Array<PulseFilter> = [];
    domainFilters.forEach((domainFilter) => {
      switch (domainFilter.filterType) {
        case FilterType.Categorical: {
          const filter = domainFilter as CategoricalPulseFilter;
          if (filter) {
            filters.push(this.convertCategoricalFilter(filter));
          } else {
            throw new Error('Invalid Categorical Filter');
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    return filters;
  }

  private convertCategoricalFilter(domainFilter: CategoricalPulseFilter): CategoricalPulseFilter {
    return new CategoricalPulseFilter(
      domainFilter.fieldName,
      domainFilter.metricId,
      FilterType.Categorical,
      this._registryId,
      domainFilter.appliedValues,
      domainFilter.isExcludeMode,
      domainFilter.isAllSelected,
    );
  }
}
