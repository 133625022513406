import { ClassNameKey, ExecuteParameters, ExecuteResponse, ExtensionBootstrapInfo, Notification, VerbId } from '../JsApiInternalContract';
import { VisualId, WorkbookFormatting } from '../contract/Models';
import { NotificationId } from '../contract/Notifications';

// tslint:disable:no-any

/** This function is called when we receive old vers and parameters from the external before we send it to platform */
export type UpgradeExecuteCall = (verb: VerbId, parameters: ExecuteParameters) => { verb: VerbId; parameters: ExecuteParameters };

/** This function is called when we received a response back from platform and we need to downgrade it to external's version */
export type DowngradeExecuteReturn = (executeResponse: ExecuteResponse) => ExecuteResponse;

/** This function is called when we receive a notification from platform and we need to downgrade it to external's version */
export type DowngradeNotification = (notification: Notification) => Notification;

// This is where we will start to define some of these translations.
// When modifying existing models, add the requisite conversion functions here, then use them
// in the VersionConverterFactory implementation. Import old versions as you would any other module

// 0 <-> Translations
// Uncomment this line to import from the V0 definition of the API
// import * as V0 from '@tableau-api-internal-contract-js_v0';

// 1 <-> 2 Translations
// Uncomment this line to import from the V1 definition of the API
// import * as V1 from '@tableau-api-internal-contract-js_v1';

export function DowngradeWorksheetNames(executeResponse: ExecuteResponse): ExecuteResponse {
  // Fix the dashboard friendly name issue. The structures are compatible,
  // so we still return the original reply, but we copy the SheetInfo.name
  // into the DashboardZone.name, where v1 wants to find it.

  const bootstrapInfo = executeResponse.result as ExtensionBootstrapInfo;
  if (bootstrapInfo.extensionDashboardInfo !== undefined) {
    bootstrapInfo.extensionDashboardInfo.zones.forEach((zone) => {
      if (zone.sheetInfo) {
        zone.name = zone.sheetInfo.name;
      }
    });
  }

  return executeResponse;
}

export function DowngradeFlipboardZoneID(notification: Notification): Notification {
  // Fix the FlipboardZoneId issue. Older external versions still check for flipboardZoneIDs.
  // When running against a newer server, if flipboardZoneId is absent, set it to default(0).

  if (notification.notificationId === NotificationId.SelectedMarksChanged) {
    const visualModel = notification.data as VisualId;
    if (visualModel.flipboardZoneID === undefined) {
      visualModel.flipboardZoneID = 0;
    }
  }

  return notification;
}

// Filter out formatting sheets that are specified in the classNames list
function DowngradeWorksheetFormatting(executeResponse: ExecuteResponse, classNames: ClassNameKey[]): ExecuteResponse {
  const bootstrapInfo = executeResponse.result as ExtensionBootstrapInfo;

  if (bootstrapInfo?.extensionWorksheetInfo?.formatting)
    bootstrapInfo.extensionWorksheetInfo.formatting.formattingSheets =
      bootstrapInfo.extensionWorksheetInfo.formatting.formattingSheets.filter((x) => !classNames.includes(x.classNameKey));
  return executeResponse;
}

// Filter out formatting sheets that are specified in the classNames list
// Filter out formatting sheets that are specified in the classNames list
function DowngradeWorksheetFormattingNotification(notification: Notification, classNames: ClassNameKey[]): Notification {
  if (notification.notificationId === NotificationId.WorksheetFormattingChanged) {
    const formatting = notification.data as WorkbookFormatting;
    notification.data = formatting.formattingSheets.filter((x) => !classNames.includes(x.classNameKey));
  }

  return notification;
}

// We added new enum values for ClassNameKeys enum in 176. Need to filter them out if the client version is <176
const ClassNameKeysToFilter175 = [ClassNameKey.RowDividers, ClassNameKey.ColumnDividers, ClassNameKey.Pane];

export const DowngradeWorksheetFomatting175 = (response: ExecuteResponse) =>
  DowngradeWorksheetFormatting(response, ClassNameKeysToFilter175);
export const DowngradeWorksheetFomattingNotification175 = (notification: Notification) =>
  DowngradeWorksheetFormattingNotification(notification, ClassNameKeysToFilter175);
