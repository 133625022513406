// All enum values made available to Extensions developers.
// Enums should be kept in alphabetical order.

import { Extensions } from './Extensions';

namespace Tableau {
  export let extensions: Extensions;
  /**
   * All error codes used by the Extensions API.
   */
  export enum ErrorCodes {
    /**
     * Thrown when caller attempts to execute command before initialization has completed.
     */
    APINotInitialized = 'api-not-initialized',
    /**
     * Thrown when caller attempts to execute command while extension is not visible.
     */
    VisibilityError = 'visibility-error',
    /**
     * Only one dialog can be opened at time with the UI namespace functionality.
     */
    DialogAlreadyOpen = 'dialog-already-open',
    /**
     * The open dialog was closed by the user.
     */
    DialogClosedByUser = 'dialog-closed-by-user',
    /**
     * An error occurred within the Tableau Extensions API. Contact Tableau Support.
     */
    InternalError = 'internal-error',
    /**
     * A dialog must start on the same domain as the parent extenion.
     */
    InvalidDomainDialog = 'invalid-dialog-domain',
    /**
     * A parameter is not the correct data type or format. The name of the parameter is specified in the Error.message field.
     */
    InvalidParameter = 'invalid-parameter',
    /**
     * Can occur if the extension interacts with a filter that has been removed from the worksheet.
     */
    MissingFilter = 'missing-filter',
    /**
     * Can occur if the extension interacts with a parameter that has been removed from the worksheet.
     */
    MissingParameter = 'missing-parameter',
    /**
     * Internal Server Error
     */
    ServerError = 'server-error',
    /**
     * Developer cannot save settings while another save is still in progress.
     */
    SettingSaveInProgress = 'setting-save-in-progress',
    /**
     * An unknown event name was specified in the call to `addEventListener` or `removeEventListener`.
     */
    UnsupportedEventName = 'unsupported-event-name',
    /**
     * A method was used for a type of data source that doesn't support that method (see getActiveTablesAsync for an example)
     */
    UnsupportedMethodForDataSourceType = 'unsupported-method-for-data-source-type',
  }

  /**
   * The context in which the Extensions is currently running.
   */
  export enum ExtensionContext {
    Desktop = 'desktop',
    Server = 'server',
  }

  /**
   * The mode in which the Extensions is currently running.
   */
  export enum ExtensionMode {
    Authoring = 'authoring',
    Viewing = 'viewing',
  }

  /**
   * Represents the type of event that can be listened for.
   */
  export enum TableauEventType {
    /** Raised when any filter has changed state. You can use this event type with [Worksheet](../interfaces/worksheet.html) objects.*/
    FilterChanged = 'filter-changed',

    /** The selected marks on a visualization has changed.
     * You can use this event type with [Worksheet](../interfaces/worksheet.html) objects. */
    MarkSelectionChanged = 'mark-selection-changed',

    /** The summary data backing a worksheet has changed
     * You can use this event type with [Worksheet](../interfaces/worksheet.html) objects.*/
    SummaryDataChanged = 'summary-data-changed',

    /** A parameter has had its value modified. You can use this event type with [[Parameter]] objects. */
    ParameterChanged = 'parameter-changed',

    /** Settings have been changed for this extension. You can use this event type with [[Settings]] objects. */
    SettingsChanged = 'settings-changed',

    /** The dashboard layout has changed
     * @category Dashboard Extensions
     */
    DashboardLayoutChanged = 'dashboard-layout-changed',

    /** The workbook formatting has changed
     * @category Dashboard Extensions
     */
    WorkbookFormattingChanged = 'workbook-formatting-changed',

    /** The worksheet formatting has changed
     * @category Viz Extensions
     */
    WorksheetFormattingChanged = 'worksheet-formatting-changed',
  }

  /**
   * Enum that represents the data type of encodings for createVizImageAsync.
   * @since 1.6.0
   */
  export enum VizImageEncodingType {
    Discrete = 'discrete',
    Continuous = 'continuous',
  }

  /**
   * Enum that represents the sort direction for createVizImageAsync.
   * @since 1.8.0 and Tableau 2021.4
   */
  export enum VizImageSortDirectionType {
    Ascending = 'ascending',
    Descending = 'descending',
  }

  /**
   * Enum that represents the palette type for createVizImageAsync.
   * @since 1.8.0 and Tableau 2021.4
   */
  export enum VizImagePaletteType {
    CustomDiverging = 'custom-diverging',
    CustomSequential = 'custom-sequential',
  }

  /**
   * Enum that represents the Size Setting type for createVizImageAsync.
   * @since 1.8.0 and Tableau 2021.4
   */
  export enum VizImageSizeSettingType {
    Fixed = 'fixed',
    Manual = 'manual',
  }

  /**
   * Enum that represents the Size Setting alignment type for createVizImageAsync.
   * @since 1.8.0 and Tableau 2021.4
   */
  export enum VizImageSizeSettingAlignmentType {
    Right = 'right',
    Left = 'left',
    Center = 'center',
  }
}
export = Tableau;
