import * as Contract from '@tableau/api-external-contract-js';

export class FirstPulseMetricSizeKnownEvent implements Contract.FirstPulseMetricSizeKnownEvent {
  private _width: number;
  private _height: number;

  public get width(): number {
    return this._width;
  }

  public get height(): number {
    return this._height;
  }

  public constructor(width: number, height: number) {
    this._width = width;
    this._height = height;
  }
}
