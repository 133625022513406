export enum PulseOptionNames {
  embed = 'embed',
  token = 'token',
  disableExploreFilter = 'disableExploreFilter',
  layout = 'embed_layout',
  theme = 'embed_theme',
  ApiExternalVersion = ':apiExternalVersion',
  ApiInternalVersion = ':apiInternalVersion',
  PulseWebComponent = ':pulseWebComponent',
}
