import * as Contract from '@tableau/api-external-contract-js';
import { ApiServiceRegistry, TableauError } from '@tableau/api-shared-js';
import { EmbeddingServiceNames } from '../Services';
import { PulseService } from '../Services/PulseService';

export class PulseFiltersChangedEvent implements Contract.PulseFiltersChangedEvent {
  private _fieldNames: Array<string>;
  private _embeddingId: number;

  public constructor(fieldNames: Array<string>, embeddingId: number) {
    this._fieldNames = fieldNames;
    this._embeddingId = embeddingId;
  }

  public get fieldNames(): Array<string> {
    return this._fieldNames;
  }

  public getFiltersAsync(): Promise<Array<Contract.PulseFilter>> {
    const service = ApiServiceRegistry.get(this._embeddingId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.getFiltersAsync().then((filters) => {
      const eventedFilters: Array<Contract.PulseFilter> = [];
      const missingFields: Array<string> = [];

      for (const fieldName of this._fieldNames) {
        const eventedFilter = filters.find((filter) => filter.fieldName === fieldName);

        if (eventedFilter) {
          eventedFilters.push(eventedFilter);
        } else {
          missingFields.push(fieldName);
        }
      }

      if (missingFields.length) {
        throw new TableauError(
          Contract.EmbeddingErrorCodes.FilterMissingOrNotImplemented,
          `Cannot find filter(s): ${missingFields.join(', ')}.`,
        );
      }

      return eventedFilters;
    });
  }
}
