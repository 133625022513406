import { FilterOptions } from '../Shared/FilterInterfaces';
import { FilterDomainType, FilterType, FilterUpdateType } from '../Shared/Namespaces/Tableau';
import { PulseLayout, PulseTimeDimension } from './Enums';
import { WebComponentLocalSettings } from './WebComponentInterfaces';

/**
 * The names of the string constants you can use as HTML attributes for the <tableau-pulse> web component.
 * @ignore
 */
export enum PulseAttributes {
  DisableExploreFilter = 'disable-explore-filter',
  Layout = 'layout',
  OnFirstInteractive = 'onFirstInteractive',
  OnFirstPulseMetricSizeKnown = 'onFirstPulseMetricSizeKnown',
  OnPulseUrlError = 'onPulseError',
  OnPulseFiltersChanged = 'onPulseFiltersChanged',
  OnPulseInsightDiscovered = 'onPulseInsightDiscovered',
  OnPulseTimeDimensionChanged = 'onPulseTimeDimensionChanged',
  OnPulseUrlChanged = 'onPulseUrlChanged',
  TimeDimension = 'time-dimension',
  TokenOptional = 'token-optional',
}

export interface PulseSettings extends WebComponentLocalSettings {
  /**
   * Indicates whether the explore filter is hidden or visible.
   * ```
   * <tableau-pulse id="tableauPulse" disable-explore-filter>
   * ```
   */
  disableExploreFilter?: boolean;

  /**
   * Specifies the desired custom layout of the Pulse metric.
   * ```
   * <tableau-pulse id="tableauPulse" layout="card">
   * ```
   */
  layout?: PulseLayout;

  /**
   * The token used for authorization
   *
   * ```
   * <tableau-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-authoring-viz id="tableauViz" token="some-token-containing-clientId" />
   * <tableau-pulse id="tableauPulse" token="some-token-containing-clientId" />
   * ```
   */
  token?: string;

  /**
   * The value of the 'loading' attribute of the embedded iframe.
   * See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#loading
   *
   * ```
   * <tableau-viz id="tableauViz" iframe-attr-loading="lazy" />
   * <tableau-authoring-viz id="tableauViz" iframe-attr-loading="lazy" />
   * <tableau-pulse id="tableauPulse" iframe-attr-loading="lazy" />
   * ```
   */
  iframeAttributeLoading?: string;

  /**
   * The value of the 'style' attribute of the embedded iframe.
   *
   * ```
   * <tableau-viz id="tableauViz" iframe-attr-style="border: 1px solid red" />
   * <tableau-authoring-viz id="tableauViz" iframe-attr-style="border: 1px solid red" />
   * <tableau-pulse id="tableauPulse" iframe-attr-style="border: 1px solid red" />
   * ```
   */
  iframeAttributeStyle?: string;

  /**
   * The value of the 'class' attribute of the embedded iframe providing access to any
   * custom selectors defined in the `<iframe-style>` child tag.
   *
   * ```
   * <tableau-pulse id="tableauViz" iframe-attr-class="red-border">
   *   <iframe-style>
   *     .red-border {
   *       border: 1px solid red;
   *     }
   *   </iframe-style>
   * </tableau-pulse>
   * ```
   */
  iframeAttributeClass?: string;

  /**
   * The Base64-encoded string representation of the Pulse theme object.
   *
   */
  theme?: string;

  /**
   * Specifies the desired time dimension to apply to the Pulse metric by default.
   * ```
   * <tableau-pulse id="tableauPulse" time-dimension="MonthToDate">
   * ```
   */
  timeDimension?: PulseTimeDimension;

  /**
   * Indicates whether the token is optional.
   * When true, the metric will attempt to load even without specifying a token.
   * This was added specifically for the Mobile team who uses PKCE flow to initiate the session
   * and should not be advertised to 3rd parties.
   *
   * @ignore
   *
   * ```
   * <tableau-pulse id="tableauPulse" token-optional>
   * ```
   */
  isTokenOptional?: boolean;
}

/**
 * The names of the string constants you can use as child tags for TableauPulse custom element.
 */
export enum PulseChildElements {
  /**
   * Custom styles applicable to the embedded iframe element itself (not inside it).
   */
  IframeStyle = 'iframe-style',

  /**
   * A parameter to be used in the Pulse theme.
   */
  ThemeParameter = 'theme-parameter',

  /**
   * Contains a list of filter parameters.
   */
  PulseFilter = 'pulse-filter',
}

/**
 * The attributes of the <theme-parameter> child elements.
 */
export interface PulseThemeProperty {
  /**
   * Name of the theme parameter to be set.
   */
  name: string;

  /**
   * Value for the theme parameter being set.
   */
  value: string;

  /**
   * Optional type of theme parameter being set.
   */
  type: string | null;
}

/**
 * The attributes of the child elements of <tableau-pulse>.
 */
export enum PulseChildElementAttributes {
  Name = 'name',
  Field = 'field',
  Value = 'value',
  Type = 'type',
}

/**
 * The Pulse theme data structure.
 */
export type PulseTheme = Record<string, string | Record<string, string>>;

/**
 * Actions related to events and state of the Pulse metric
 */
export interface PulseActions {
  /**
   * Applies the list of provided categorical filter values to the Pulse metric.
   *
   * @param fieldName      The name of the field to filter on.
   * @param values         The list of values to filter on.
   * @param updateType     The update type of this filter (add, all, remove, replace).
   * @param filterOptions  Advanced filter options (isExcludeMode).
   * @returns The field name that the filter is applied on.
   */
  applyFilterAsync(fieldName: string, values: PulseFieldValueArray, updateType: FilterUpdateType, options: FilterOptions): Promise<string>;

  /**
   * Applies at least one categorical filter value to the Pulse metric.
   *
   * @param {Array<{
   *       fieldName: string;
   *       values: PulseFieldValueArray;
   *       updateType: FilterUpdateType;
   *       options: FilterOptions;
   *     }>} filters The set of filters to apply.
   * @returns The field names that the filter was applied on.
   */
  applyFiltersAsync(
    filters: Array<{
      fieldName: string;
      values: PulseFieldValueArray;
      updateType: FilterUpdateType;
      options: FilterOptions;
    }>,
  ): Promise<Array<string>>;

  /**
   * Gets the current time dimension applied to the Pulse metric.
   *
   * @returns The current time dimension.
   */
  getTimeDimensionAsync(): Promise<PulseTimeDimension>;

  /**
   * Applies the time dimension to the Pulse metric.
   *
   * @param {PulseTimeDimension} timeDimension The time dimension to apply
   * @returns {Promise<void>}
   */
  applyTimeDimensionAsync(timeDimension: PulseTimeDimension): Promise<void>;

  /**
   * Gets a list of filters for the Pulse metric.
   *
   * @returns {Promise<Array<PulseFilter>>} The list of filters.
   */
  getFiltersAsync(): Promise<Array<PulseFilter>>;

  /**
   * Resets the existing filter for the given field on the Pulse metric.
   *
   * @param fieldName  The name of the field to clear filter on.
   *
   * @returns The field to clear filter on.
   */
  clearFilterAsync(fieldNames: string): Promise<string>;

  /**
   * Resets the existing filter for the given fields on the Pulse metric.
   *
   * @param {Array<string>} fieldNames The name of the fields to clear filter on.
   * @returns The fields to clear filter on.
   */
  clearFiltersAsync(fieldNames: Array<string>): Promise<Array<string>>;

  /**
   * Resets all the existing filters on the Pulse metric.
   */
  clearAllFiltersAsync(): Promise<void>;

  /**
   * Use this method to filter the Pulse metric before initialization. If used after initialization, it will re-render the metric.
   * For filtering after initialization, use the other filtering methods, such as `applyFilterAsync`.
   *
   * If you add the same filter fields using the `addFilter()` method and by using the `<pulse-filter>` element in the `<tableau-pulse>` web component,
   * you might experience unexpected behavior.
   *
   * @param fieldName The name of the field to filter on.
   * @param value Single value or a list of comma separated values to filter on.
   *
   * ```
   * pulse.addFilter('Region', 'Central,West');
   * ```
   */
  addFilter(fieldName: string, value: string): void;

  /**
   * Use this method to readjust the dimensions of the embedded Pulse metric in response to things like a window resize, device orientation change, or
   * parent container resize.
   *
   * ```
   * window.addEventListener('resize', () => pulse.resize());
   *
   * new ResizeObserver(() => pulse.resize()).observe(pulse.parentElement);
   * ```
   */
  resize(): void;
}

/**
 * The interface for the top-level Pulse object.
 **/
export interface Pulse extends PulseSettings, PulseActions {}

/**
 * A PulseFieldValueArray is an array that can contain strings, booleans, or nulls, but will never contain both strings and booleans at the same time.
 */
export type PulseFieldValueArray = Array<boolean | null> | Array<string | null>;

/**
 *
 * An abstract base class for all of the Pulse filter types.
 */
export interface PulseFilter {
  /**
   * The name of the field being filtered.  Note that this is the caption
   * as shown in the UI, and not the actual database field name.
   */
  fieldName: string;

  /**
   * The type of the filter.
   */
  filterType: FilterType;

  /**
   * The Pulse metric ID.
   */
  metricId: string;
}

/**
 * A Pulse Categorical Filter
 */
export interface CategoricalPulseFilter extends PulseFilter {
  /**
   * A list of values applied to this categorical filter. Notice that if the Pulse metric
   * has a definition filter, the current relevant values can be fetched by calling
   * `getDomainAsync(FilterDomainType.Relevant)`.
   */
  readonly appliedValues: PulseFieldValueArray;

  /**
   * True if this filter is an exclude filter, false if an include filter.
   */
  readonly isExcludeMode: boolean;

  /**
   * True if all the values are selected for this filter. When 'All' is selected,
   * appliedValues returns an empty list.
   */
  readonly isAllSelected?: boolean;

  /**
   * Gets the domain of a categorical filter.
   *
   * @param {(string | undefined)} searchTerm The term to search for in the domain.
   * @param {(number | undefined)} pageSize The maximum number of values to return. Ignored when nextPageToken is not provided.
   * @param {(string | undefined)} nextPageToken The next page token returned by Pulse when additional values are available.
   * @param {(FilterDomainType | undefined)} domainType The domain type of the filter.
   * @returns {Promise<PulseCategoricalDomain>} A promise containing the categorical domain for the filter.
   */
  getDomainAsync: (
    searchTerm?: string,
    pageSize?: number,
    nextPageToken?: string,
    domainType?: FilterDomainType,
  ) => Promise<PulseCategoricalDomain>;
}

/**
 * The domain of a Pulse categorical filter
 */
export interface PulseCategoricalDomain {
  /**
   * The domain type (relevant, database)
   */
  readonly type: FilterDomainType;
  /**
   * The list of values in the domain of the filter
   */
  readonly values: PulseFieldValueArray;
  /**
   * The total available number of values in the domain of the filter.
   */
  readonly totalAvailable: number;
  /**
   * The next page token returned by Pulse when additional values are available.
   * Provide this token to getDomainAsync to get the next page of values.
   */
  readonly nextPageToken: string | undefined;
}
