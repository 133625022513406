import * as Contract from '@tableau/api-external-contract-js';
import { ApiServiceRegistry, ErrorHelpers } from '@tableau/api-shared-js';
import { EmbeddingServiceNames } from '../Services';
import { PulseService } from '../Services/PulseService';

export class PulseFilter implements Contract.PulseFilter {
  public constructor(
    protected _fieldName: string,
    protected _filterType: Contract.FilterType,
    protected _metricId: string,
    protected _registryId: number,
  ) {}

  public get fieldName(): string {
    return this._fieldName;
  }

  public get filterType(): Contract.FilterType {
    return this._filterType;
  }

  public get metricId(): string {
    return this._metricId;
  }
}

export class CategoricalPulseFilter extends PulseFilter implements Contract.CategoricalPulseFilter {
  public constructor(
    fieldName: string,
    metricId: string,
    filterType: Contract.FilterType,
    registryId: number,
    private _appliedValues: Contract.PulseFieldValueArray,
    private _isExcludeMode: boolean,
    private _isAllSelected?: boolean,
  ) {
    super(fieldName, filterType, metricId, registryId);
  }

  public get isAllSelected(): boolean | undefined {
    return this._isAllSelected;
  }

  public get appliedValues(): Contract.PulseFieldValueArray {
    return this._appliedValues;
  }

  public get isExcludeMode(): boolean {
    return this._isExcludeMode;
  }

  public getDomainAsync(
    searchTerm: string,
    pageSize?: number,
    nextPageToken?: string,
    domainType?: Contract.FilterDomainType,
  ): Promise<Contract.PulseCategoricalDomain> {
    if (!domainType) {
      domainType = Contract.FilterDomainType.Relevant;
    }

    ErrorHelpers.verifyEnumValue<Contract.FilterDomainType>(domainType, Contract.FilterDomainType, 'Contract.FilterDomainType');

    const service = ApiServiceRegistry.get(this._registryId).getService<PulseService>(EmbeddingServiceNames.PulseService);
    return service.getCategoricalDomainAsync(this._fieldName, domainType, searchTerm, pageSize, nextPageToken);
  }
}
