import * as Contract from '@tableau/api-external-contract-js';

export class PulseErrorEvent implements Contract.PulseErrorEvent {
  private _message: string;
  private _httpStatus: number;
  private _messageVisibility: Contract.PulseErrorMessageVisibility;

  public get message(): string {
    return this._message;
  }

  public get httpStatus(): number {
    return this._httpStatus;
  }

  public get messageVisibility(): Contract.PulseErrorMessageVisibility {
    return this._messageVisibility;
  }

  public constructor(message: string, httpStatus: number, messageVisibility: Contract.PulseErrorMessageVisibility) {
    this._message = message;
    this._httpStatus = httpStatus;
    this._messageVisibility = messageVisibility;
  }
}
