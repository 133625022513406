export enum NotificationId {
  CustomMarkContextMenuClicked = 'custom-mark-context-menu-clicked',
  ContextMenuClick = 'context-menu-click',
  CustomViewRemoved = 'custom-view-removed',
  CustomViewSaved = 'custom-view-saved',
  CustomViewSetDefault = 'custom-view-set-default',
  CustomViewsLoaded = 'custom-views-loaded',
  DashboardLayoutChanged = 'dashboard-layout-changed',
  EditButtonClicked = 'edit-button-clicked',
  EditInDesktopButtonClicked = 'edit-in-desktop-button-clicked',
  ExtensionDialogUpdate = 'extension-dialog-update',
  FilterChanged = 'filter-changed',
  FirstVizSizeKnown = 'first-viz-size-known',
  FirstPulseMetricSizeKnown = 'first-pulse-metric-size-known',
  ParameterChanged = 'parameter-changed',
  PulseError = 'pulse-error',
  PulseFiltersChanged = 'pulse-filters-changed',
  PulseInsightDiscovered = 'pulse-insight-discovered',
  PulseInteractive = 'pulse-interactive',
  PulseTimeDimensionChanged = 'pulse-time-dimension-changed',
  PulseUrlChanged = 'pulse-url-changed',
  SelectedMarksChanged = 'selected-marks-changed',
  SettingsChanged = 'settings-changed',
  SummaryDataChanged = 'summary-data-changed',
  TestConversionNotification = 'test-conversion-notification',
  ToolbarStateChanged = 'toolbar-state-changed',
  VizInteractive = 'viz-interactive',
  AskDataInteractive = 'ask-data-interactive',
  WorkbookReadyToClose = 'workbook-ready-to-close',
  WorkbookFormattingChanged = 'workbook-formatting-changed',
  WorkbookPublished = 'workbook-published',
  WorkbookPublishedAs = 'workbook-published-as',
  WorksheetFormattingChanged = 'worksheet-formatting-changed',
  UrlAction = 'url-action',
  TabSwitched = 'tab-switched',
  StoryPointSwitched = 'story-point-switched',
  ReadyToReceiveAuthToken = 'ready-to-receive-auth-token',
}
