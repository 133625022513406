import * as Contract from '@tableau/api-external-contract-js';

export class PulseTimeDimensionChangedEvent implements Contract.PulseTimeDimensionChangedEvent {
  private _timeDimension: Contract.PulseTimeDimension;

  public get timeDimension(): Contract.PulseTimeDimension {
    return this._timeDimension;
  }

  public constructor(timeDimension: Contract.PulseTimeDimension) {
    this._timeDimension = timeDimension;
  }
}
